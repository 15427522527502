import React from 'react';
import '../../style/main.scss';

import Footer from '../../components/Footer/Footer';

const Shenkii = () => {
  return (
    <>
      <div className="case">
        <div className="case-title">
          <h1>WIP</h1>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Shenkii;
